export const home = {
    p1: 'Auto check-in',
    p2: 'Auto check-in',
    p3: 'For hotels and tourist apartments',
    p4: 'Make check-in easier for your guests through QR code scanning or WhatsApp link',
    p5: "Make check-in easier for your guests through QR code scanning or WhatsApp link",
    p6: "Try the PREMIUM plan for free for 14 days",
    p7: 'Get started now!',
    p8: 'Schedule a demo',
    p9: "Make check-in easier for your guests through QR code scanning or WhatsApp link",
    p10: "Quick and easy.",
    p11: "For all types of Tourist Accommodations",
    p12: "Discover how we adapt to you",
    p13: "",
    p14: "There will be no queues or unnecessary waits at the reception desk.",
    p15: "Your guests can complete the check-in process directly from their mobile devices, anytime, anywhere convenient for them, or at the hotel itself by scanning the QR code.",
    p16: `HOW DOES`,
    p17: `QRCHECKIN WORK?`,
    p18: `Our online check-in process is quick, simple, and hassle-free`,
    p19: `It consists of three simple steps that will allow your guests to check in efficiently`,
    p20: `Here's how it works!`,
    p21: `Scan QR code or WhatsApp link`,
    p22: `Once your guests arrive at the hotel, they will find a QR code or receive a direct link to our online check-in web app.`,
    p23: `Scan Document (ID/Passport)`,
    p24: `We will request them to scan their valid identification document, either passport or national identity document.`,
    p25: `Sign Accommodation Policies`,
    p26: `Once the document is scanned, they will reach the final stage of the check-in process. Here they can electronically sign using the same system.`,
    p27: ` Automatic welcome message via WhatsApp`,
    p28: `Guests will automatically receive a personalized welcome message. You have the opportunity to add a special touch by customizing the message for each of them.`,
    p29: `Create your account`,
    p30: `Offer services and experiences to your guest's WhatsApp`,
    p31: `Offer your guests personalized experiences, own or external services, transportation, leisure activities... And generate more money from each booking and more direct bookings!`,
    p33: `Automatic transmission of parts to the police`,
    p34: `For your peace of mind and compliance with the legislation, our system sends nightly reports of registered travelers to the corresponding authorities`,
    p35: `You will significantly reduce the time spent by your reception staff.`,
    p36: `DON'T MISS OUT`,
    p37: `Implement our online check-in system and provide your guests with a hassle-free welcome and exceptional service from the moment they arrive.`,
    p38: `Terms and conditions`,
    p39: `Name is required`,
    p40: `Hotel name is required`,
    p41: `Phone is required`,
    p42: `Email is required`,
    p43: `Privacy policies must be accepted`,
    p44: `Error processing request`,
    p45: `Sending message...`,
    p46: `Full name`,
    p47: `Email`,
    p48: `Phone`,
    p49: `Write us what you need`,
    p50: `Message sent successfully`,
    p51: `The sales team will contact you as soon as possible`,
    p52: `Close`,
    p53: `Contact`,
    p54: `Subject is required`,
    p55: `Error processing request`,
    p56: `Apartments`,
    p57: `Villas`,
    p58: `Hotels`,
    p59: `Campsites`,
    p60: `Send alerts to your guests at specific times via WhatsApp`,
    p61: `Our alert system allows you to send personalized messages to your guests at specific hours via WhatsApp.`,
    p62: `You can schedule alerts to remind them about events, available tours first thing in the morning, or simply send them the restaurant menu an hour before lunch 😉`,
    p63: `Hello, how can I help you?`,


    // General Corrections
    p64: `check-in`,
    p65: `of your guests`,
    p66: `ID - Passport Scanner`,
    p67: `Digital signature`,
    p68: `Transmission to the police`,
    p69: `Identity verification`,
    p70: `Cross-selling`,
    p71: `Automatic Ratings`,
    p72: `Try it for Free`,
    p73: `Expedite`,
    p74: `Automate`,
    p75: `Save`,

    p76: `WE INTEGRATE WITH OVER 50 INTEGRATED RESERVATION MANAGERS (PMS)`,

    p77: `The data speaks for itself!`,
    p78: `Properties using us`,
    p79: `Completed check-ins`,
    p80: `Countries deployed`,

    p81: `Identity Verification`,
    p82: `We verify the identity of your guests through our biometric comparison software, ensuring that their documentation matches the person accessing your property.`,


    p83: `Online Payments`,
    p84: `Request online payments related to the reservation, offers and experiences, tourist taxes, and any customized payments you want to include during online check-in or during their stay.`,
    
    p85: `CONTACT QRCheckin`,
    p86: `Privacy Policies (Guest)`,
    p87: `Privacy Policies (Accommodation)`,

    p88: `Eco-friendly and hassle-free check-in! Your arrival easy and paperless, together
        caring for our planet.`,
    p89: `Eco-friendly and hassle-free check-in! Together, caring for our planet.`,

    p90: "Demonstration Processes",
    p91: "QR Check-in",
    p92: "Scan the accommodation's QR code",
    p93: "WhatsApp Check-in",
    p94: "QR DEMO",
    p95: "You can scan the QR code to perform your demonstration check-in",
    p96: "Enable Identity Verification",
    p97: "Enabled",
    p98: "Disabled",
    p99: "Security Code",
    p100: "With this QR code, you can initiate a demonstration check-in process at an accommodation.",
    p101: "Access",

    p102: "Sending demo link",
    p103: "Please wait...",
    p104: "Demo link successfully sent",
    p105: "You will receive a message on WhatsApp to initiate the check-in process",
    p106: "DEMONSTRATION PROCESS",
    p107: "Enter your details to receive our check-in demo process on your WhatsApp.",
    p108: "Enable Identity Verification",
    p109: "Name",
    p110: "Email",
    p111: "Phone",
    p112: "Send Link",
    p113: "We are integrated with SES.HOSPEDAJES, the new platform for traveler registration operated by the Ministry of the Interior.",
    p114: `Hello, I would like to receive more information about WACheckin (Sales).`,
    p115: `We are integrated with SES.HOSPEDAJES, the Ministry of the Interior's registration platform.`


}
