export const nav = {
  p1: `Features`,
  p2: `Integrations`,
  p3: `Pricing`,
  p4: `Watch video`,
  p5: `START NOW!`,
  p6: `Login`,

  p7: `QR Checkin`,
  p8: "Your guests will be able to check-in upon arrival by scanning the QR code of your accommodation.",

  p9: `Checkin Online`,
  p10: "Your guests will receive a link to check-in before they arrive at your accommodation.",

  p11: `WhatsApp Welcome`,
  p12: `Offer a welcome message through WhatsApp with the check-in information automatically.`,

  p13: `Cross Selling`,
  p14: `Your guests will have access to the products, services or tours that you offer in your accommodation on their WhatsApp.`,

  p15: `Sending to authorities`,
  p16: `We automatically send dialy travelers reports to local authorities.`,

  p17: `Scheduled messages`,
  p18: `Schedule automatic messages that will be sent at specific times: (ex. Send the restaurant menu at 11AM.)`,
  p21: `Features`,
  p22: `Automatic Google reviews`,

  p23: "Links of interest",
  p24: "Terms and conditions",
  p25: "Privacy policies (Guest)",
  p26: "Privacy policies (Accommodation)",
  p27: "Contact sales",
  p28: "Contact support",
  p29: "All rights reserved",
  p30:`Contact`,
};
