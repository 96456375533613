<template>
  <div>
    <div
      class="navInfo d-flex flex-row"
      style="background: #000;
        color: white;
        position: fixed;
        z-index: 2;
        left: -0;
        right: 0;
        padding-inline:10px;
        height: 55px 
        text-align: center !important;
        align-items: center;
    font-size: 15px;
        margin: auto !important;
        top: 0;
        justify-content: center !important;"
    >
      <div>
        {{ $t("home.p115") }}
      </div>
      <!-- <div class="ml-4" style="text-decoration: underline; font-weight: 600">
        Más información <i class="far fa-chevron-right ml1"></i>
      </div> -->
    </div>

    <div class="navMovil">
      <div
        class="div d-flex"
        style="
          height: 100%;
          align-items: center;
          padding: 0 23px;
          justify-content: space-between;
        "
      >
        <div class="logo">
          <a href="/">
            <img
              src="/logo_movile.png"
              class="img-fluid"
              alt=""
              v-animate-css="'slideInDown'"
              style="width: 160px"
            />
          </a>
        </div>

        <div class="icon-idioma" style="margin-top: 4px">
          <img
            v-if="idioma == 'es'"
            @click.prevent="activarModalIdiomas()"
            src="/espana.png"
            style="width: 22px; cursor: pointer"
            alt=""
          />
          <img
            v-if="idioma == 'co'"
            @click.prevent="activarModalIdiomas()"
            src="/colombia.png"
            style="width: 22px; cursor: pointer"
            alt=""
          />
          <img
            v-if="idioma == 'en'"
            @click.prevent="activarModalIdiomas()"
            src="/uuee.png"
            style="width: 22px; cursor: pointer"
            alt=""
          />
          <img
            v-if="idioma == 've'"
            @click.prevent="activarModalIdiomas()"
            src="/venezuela.png"
            style="width: 22px; cursor: pointer"
            alt=""
          />

          <!-- <i
            class="icon-leng fas fa-globe"
            @click.prevent="activarModalIdiomas()"
            style="font-size: 20px; margin-right: 41px; cursor: pointer"
          ></i> -->
        </div>

        <!-- <div
        @click.prevent="abrirModal()"
        style="
          padding-top: 3px !important;
          background: rgb(76, 175, 80);
          margin-top: 6px;
          border-radius: 10px;
          padding-left: 10px;
          padding-right: 10px;
          color: white;
          padding-bottom: 3px !important;
          width: fit-content;
          font-weight: 500;
          font-size: 14px !important;
        "
      >
        <i class="fad fa-play-circle mr-2"></i> {{ $t("nav.p4") }}
      </div> -->

        <div class="desplegable">
          <i
            v-if="!open"
            @click="open = true"
            class="fa-regular fa-bars"
            style="cursor: pointer; font-size: 33px; color: #333; transition: 0.5s"
          ></i>
          <i
            v-if="open"
            @click="open = false"
            class="fa-regular fa-times"
            style="cursor: pointer; font-size: 33px; color: #333; transition: 0.5s"
          ></i>
        </div>
      </div>

      <div class="menu" style="padding-top: 95px; overflow: scroll" v-if="open">
        <ul style="padding-right: 40px">
          <li>
            <div
              @click.prevent="abrirModal()"
              style="
                padding-top: 3px !important;
                background: rgb(76, 175, 80);
                margin-top: 6px;
                border-radius: 10px;
                padding-left: 10px;
                padding-right: 10px;
                color: white;
                padding-bottom: 3px !important;
                width: fit-content;
                font-weight: 500;
                font-size: 14px !important;
              "
            >
              <i class="fad fa-play-circle mr-2"></i> {{ $t("nav.p4") }}
            </div>
          </li>
          <li>
            <a
              href="https://app.wacheckin.com/register"
              style="text-decoration: none; color: #000"
              >{{ $t("nav.p5") }}</a
            >
          </li>

          <li>
            <a href="https://app.wacheckin.com" style="text-decoration: none; color: #000">
              {{ $t("nav.p6") }}</a
            >
          </li>
          <br />
          <hr />
          <br />

          <li
            data-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            {{ $t("nav.p1") }}
          </li>
          <div class="collapse" id="collapseExample">
            <ul>
              <li
                style="font-size: 17px !important"
                class="dropdown-item"
                href="#"
                @click.prevent="CambiarRuta('checkinOnlineRoute')"
              >
                {{ $t("nav.p7") }}
              </li>
              <li
                style="font-size: 17px !important"
                class="dropdown-item"
                href="#"
                @click.prevent="CambiarRuta('selfCheckinPage')"
              >
                {{ $t("nav.p9") }}
              </li>
              <li
                style="font-size: 17px !important"
                class="dropdown-item"
                href="#"
                @click.prevent="CambiarRuta('whatsappPage')"
              >
                {{ $t("nav.p11") }}
              </li>
              <li
                style="font-size: 17px !important"
                class="dropdown-item"
                href="#"
                @click.prevent="CambiarRuta('Legalidad')"
              >
                {{ $t("nav.p13") }}
              </li>
              <li
                style="font-size: 17px !important"
                class="dropdown-item"
                href="#"
                @click.prevent="CambiarRuta('policePage')"
              >
                {{ $t("nav.p15") }}
              </li>
              <li
                style="font-size: 17px !important"
                class="dropdown-item"
                href="#"
                @click.prevent="CambiarRuta('Upselling')"
              >
                {{ $t("nav.p17") }}
              </li>
            </ul>
          </div>

          <!-- <div class="dropdown">
            <div class="dropdown-menu" style="padding: 12px;
            border: none;">
            <a class="dropdown-item" href="#">Checkin Online</a>
            <a class="dropdown-item" href="#">Self Checkin</a>
            <a class="dropdown-item" href="#">WhatsApp Bienvenida</a>
            <a class="dropdown-item" href="#">Legalidad</a>
            <a class="dropdown-item" href="#">Envío a policía </a>
            <a class="dropdown-item" href="#">Venta Cruzada </a>
            </div>
            </div> -->

          <li @click.prevent="CambiarRuta('Integrations')">{{ $t("nav.p2") }}</li>
          <li @click.prevent="CambiarRuta('Price')">{{ $t("nav.p3") }}</li>
          <li >
            <a href="https://blog.wacheckin.com/" target="_blank" style="text-decoration:none; color:#333">
              Blog
            </a>
          </li>
          

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.idioma = localStorage.getItem("appLanguage") || "es";
  },
  data() {
    return {
      open: false,
      idioma: "",
    };
  },
  methods: {
    activarModalIdiomas() {
      $("#modalLenguaje").modal("show");
    },

    abrirModal() {
      $("#modalVideoMovil").modal("show");
      var video = document.getElementById("mi-video-movil");
      video.play();
      this.open = false;
    },

    CambiarRuta(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navMovil {
  top: 54px !important;
  position: fixed;
  left: 0;
  border-bottom: 1px solid #eee;
  right: 0;
  z-index: 2;
  height: 80px;
  background: white;
  ul {
    text-align: left;
  }

  ul li {
    font-size: 20px !important;
  }

  ul li {
    margin-bottom: 15px;
    font-size: 20px;
  }

  .menu {
    background: white;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 120px;
  }
}
</style>
